const breakpoints = {
  screenXS: '374px',
  screenSM: '750px',
  screenMD: '992px',
  screenLG: '1200px',
  screenL: '1500px',
  screenXL: '1700px',
  screenXXL: '2000px',
}

export default breakpoints
