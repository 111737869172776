import styled from 'styled-components'

import breakpoints from '../constants/breakpoints'

const Button = styled.button`
  font-size: 0.95vw;
  line-height: 1;
  letter-spacing: 0.08em;
  padding: 1.2em 3em;
  border-radius: 0;
  border: 0;
  transition: all 0.5s ease;

  @media (max-width: ${breakpoints.screenLG}) {
    font-size: 1.8vw;
    padding: 1.2em 4em;
  }

  &:hover {
    background-color: ${({ theme }) => theme.btnHoverBackground};
    border-color: ${({ theme }) => theme.btnHoverBackground};
    color: ${({ theme }) => theme.textLight};
  }

  &:disabled {
    opacity: 0.4;
    pointer-events: none;
  }
`

const ButtonPrimary = styled(Button)`
  background-color: transparent;
  border: 0.06em solid ${({ theme }) => theme.textLight};
  color: ${({ theme }) => theme.textLight};
`

const ButtonSecondary = styled(Button)`
  background-color: transparent;
  border: 0.06em solid ${({ theme }) => theme.textPrimary};
  color: ${({ theme }) => theme.textPrimary};
`

export { ButtonPrimary, ButtonSecondary }
