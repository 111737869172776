import styled from 'styled-components'

import breakpoints from '../constants/breakpoints'

const Headline = styled.h1`
  font-size: 4vw;
  line-height: 1.2;
  letter-spacing: 0.08em;
  font-weight: ${({ weight }) => weight ?? 400};

  @media (max-width: ${breakpoints.screenLG}) {
    line-height: 1.4;
  }

  @media (max-width: ${breakpoints.screenSM}) {
    line-height: 1.6;
  }
`

const Title = styled.h2`
  font-size: 3vw;
  line-height: 1.2;
  letter-spacing: 0.08em;
  font-weight: ${({ weight }) => weight ?? 400};

  @media (max-width: ${breakpoints.screenLG}) {
    font-size: 4vw;
  }
`

const Subtitle = styled.h3`
  font-size: 2.4vw;
  line-height: 1.4;
  letter-spacing: 0.08em;
  font-weight: ${({ weight }) => weight ?? 400};
`

const ParagraphM = styled.p`
  font-size: 0.9vw;
  line-height: 1.8;
  letter-spacing: 0.08em;
  font-weight: ${({ weight }) => weight ?? 400};

  @media (max-width: ${breakpoints.screenLG}) {
    font-size: 1.8vw;
  }
`

const ParagraphS = styled.p`
  font-size: 0.8vw;
  line-height: 1.8;
  letter-spacing: 0.08em;
  font-weight: ${({ weight }) => weight ?? 400};

  @media (max-width: ${breakpoints.screenLG}) {
    font-size: 1.8vw;
    line-height: 1.6;
  }
`

export { Headline, Title, Subtitle, ParagraphM, ParagraphS }
