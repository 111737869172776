import React from 'react'
import PropTypes from 'prop-types'
import { ThemeProvider } from 'styled-components'

import theme from '../constants/theme'

import GlobalStyles from '../styles/GlobalStyles'
import { PageLayout } from '../styles/PageLayout'

import Header from './Header'
import Cursor from './Cursor'
import Scroll from './Scroll'
import Chat from './Chat'
import Footer from './Footer'

/**
 * The page layout including all global styles and theme provider,
 * used as a wrapper in gatsby-browser and gatsby-ssr.
 * @param {ComponentProps} props
 * @param {ChildNode} props.children
 * @param {String} props.location
 * @returns {StatelessComponent}
 */
const Page = ({ children, location }) => (
  <>
    <GlobalStyles />
    <ThemeProvider theme={theme}>
      <PageLayout>
        <Cursor location={location} />
        <Chat />
        <Header />
        <Scroll />
        {children}
        <Footer />
      </PageLayout>
    </ThemeProvider>
  </>
)

Page.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  location: PropTypes.object.isRequired,
}

export default Page
