import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import useMousePosition from '../hooks/useMousePosition'

import { Cursor } from '../styles/PageLayout'

/**
 * A custom cursor with hovering transitions.
 * @param {ComponentProps} props
 * @param {Object} props.location
 * @returns {FunctionComponent}
 */
const CursorPosition = ({ location }) => {
  const { x, y } = useMousePosition()

  const [isHovering, setIsHovering] = useState(false)

  useEffect(() => {
    setIsHovering(false)
  }, [location.pathname])

  useEffect(() => {
    const links = document.querySelectorAll('a')
    const buttons = document.querySelectorAll('button')

    const clickables = [...links, ...buttons]

    clickables.forEach((clickable) => {
      clickable.addEventListener('mouseenter', () => setIsHovering(true))
      clickable.addEventListener('mouseleave', () => setIsHovering(false))
    })
  }, [isHovering])

  return (
    <Cursor
      style={{
        transform: `translate(${x - 35}px, ${y - 35}px) scale(0.5)`,
      }}
    >
      <div
        className="pointer"
        style={{
          transform: `translate(-50%, -50%) scale(${isHovering ? 0.67 : 1})`,
        }}
      />
    </Cursor>
  )
}

CursorPosition.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }),
}

export default CursorPosition
