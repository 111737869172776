import React, { useState } from 'react'
import { Link } from 'gatsby'

import { HeaderLayout, MenuButton } from '../styles/HeaderLayout'
import { MenuLayout } from '../styles/MenuLayout'
import { Container } from '../styles/PageLayout'
import { Headline } from '../styles/Typography'

/**
 * The main header of the website.
 * @returns {FunctionComponent}
 */
const Header = () => {
  const [menuClass, setMenuClass] = useState('')

  const handleMenuClick = () => {
    if (menuClass === 'open') {
      setMenuClass('close')
      document.documentElement.classList.remove('no-scroll')
      document.getElementById('fb-root').classList.remove('hide')
    } else {
      setMenuClass('open')
      document.documentElement.classList.add('no-scroll')
      document.getElementById('fb-root').classList.add('hide')
    }
  }

  return (
    <HeaderLayout>
      <Link to="/">
        <div className="logo">Colorized.mk</div>
      </Link>
      <div className="lang">
        <a className="lang-item">Mk</a>
        <a className="lang-item active">En</a>
      </div>
      <MenuButton>
        <a
          type="button"
          className={`btn ${menuClass}`}
          onClick={handleMenuClick}
        >
          <div className="menu">Menu</div>
          <div className="lines">
            <span className="line"></span>
            <span className="line"></span>
          </div>
        </a>
      </MenuButton>
      <MenuLayout className={menuClass}>
        <Container mobileColumn className="menu-inner">
          <Link to="/" className={menuClass} activeClassName="active">
            <Headline onClick={handleMenuClick}>Home</Headline>
          </Link>
          <Link to="/about/" className={menuClass} activeClassName="active">
            <Headline onClick={handleMenuClick}>Who we are</Headline>
          </Link>
          <Link to="/contact/" className={menuClass} activeClassName="active">
            <Headline onClick={handleMenuClick}>Contact us</Headline>
          </Link>
          <Link to="/shop/" className={menuClass} activeClassName="active">
            <Headline onClick={handleMenuClick}>Shop</Headline>
          </Link>
        </Container>
        <div className={`bg-transition ${menuClass}`} />
      </MenuLayout>
    </HeaderLayout>
  )
}

export default Header
