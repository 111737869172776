import styled, { keyframes } from 'styled-components'

import breakpoints from '../constants/breakpoints'

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`

const slideLeft = keyframes`
  0% {
    transform: translateX(4vw);
  }
  100% {
    transform: translateX(0);
  }
`

const HeaderLayout = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2.2em 4vw;
  color: ${({ theme }) => theme.textPrimary};

  @media (max-width: ${breakpoints.screenLG}) {
    padding: 1.6em 4vw;
  }

  .logo {
    font-size: 1vw;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 0.14em;
    padding: 1rem 0;
    transition: color 0.3s;
    opacity: 0;
    transform: translateX(4vw);
    animation: ${fadeIn} 0.6s ease-out forwards,
      ${slideLeft} 1.4s cubic-bezier(0.02, 0.98, 0.36, 1) forwards;
    animation-delay: 0.6s;

    @media (max-width: ${breakpoints.screenLG}) {
      font-size: 2vw;
    }

    &:hover {
      color: ${({ theme }) => theme.textHighlight};
    }
  }

  .lang {
    display: flex;
    padding-right: 8vw;
    margin-left: auto;

    @media (max-width: ${breakpoints.screenLG}) {
      padding-right: 10vw;
    }
  }

  .lang-item {
    position: relative;
    font-size: 0.85vw;
    font-weight: 400;
    margin-left: 2vw;
    opacity: 0;
    transform: translateX(4vw);
    animation: ${fadeIn} 0.6s ease-out forwards,
      ${slideLeft} 1.4s cubic-bezier(0.02, 0.98, 0.36, 1) forwards;
    animation-delay: 0.6s;

    @media (max-width: ${breakpoints.screenLG}) {
      font-size: 1.8vw;
      margin-left: 4vw;
    }

    &.active:after {
      content: '';
      position: absolute;
      left: -0.2vw;
      bottom: -12%;
      height: 0.08em;
      width: 150%;
      background-color: ${({ theme }) => theme.textPrimary};

      @media (max-width: ${breakpoints.screenLG}) {
        left: -0.4vw;
        height: 1px;
      }
    }
  }

  .menu {
    font-size: 0.95vw;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 0.16em;
    margin-right: 1vw;
    opacity: 0;
    will-change: color;
    transition-delay: 0.3s;
    transform: translateX(4vw);
    animation: ${fadeIn} 0.6s ease-out forwards,
      ${slideLeft} 1.4s cubic-bezier(0.02, 0.98, 0.36, 1) forwards;
    animation-delay: 0.6s;

    @media (max-width: ${breakpoints.screenLG}) {
      font-size: 2vw;
    }
  }
`

const MenuButton = styled.div`
  display: flex;
  align-items: center;
  z-index: 3;

  .lines {
    display: flex;
    opacity: 0;
    transform: translateX(4vw);
    animation: ${fadeIn} 0.6s ease-out forwards,
      ${slideLeft} 1.4s cubic-bezier(0.02, 0.98, 0.36, 1) forwards;
    animation-delay: 0.6s;
  }

  .line {
    height: 0.8em;
    width: 0.1em;
    background-color: ${({ theme }) => theme.textPrimary};
    margin-right: 0.4vw;
    transition: height 0.3s cubic-bezier(0.215, 0.61, 0.355, 1),
      transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1),
      background-color 0s ease 0.3s;

    @media (max-width: ${breakpoints.screenLG}) {
      height: 16px;
      width: 2px;
      margin-right: 0.8vw;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  .btn {
    display: flex;
    align-items: center;
    font-weight: inherit;
    background: none;
    border: 0;
    height: 2.5em;
    padding: 0;

    @media (max-width: ${breakpoints.screenLG}) {
      height: 50px;
    }

    &.open {
      .menu {
        color: ${({ theme }) => theme.textLight};
      }

      .line {
        background-color: ${({ theme }) => theme.textLight};
        height: 1.4em;

        &:first-child {
          transform: translate(0.25vw) rotate(-45deg);
        }

        &:last-child {
          transform: translate(-0.25vw) rotate(45deg);
        }

        @media (max-width: ${breakpoints.screenLG}) {
          height: 30px;

          &:first-child {
            transform: translate(10px) rotate(-45deg);
          }

          &:last-child {
            transform: translate(0) rotate(45deg);
          }
        }
      }
    }
  }
`

export { HeaderLayout, MenuButton }
