import styled, { keyframes } from 'styled-components'

import breakpoints from '../constants/breakpoints'

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`

const slideUp = keyframes`
  0% {
    transform: translateY(10vw);
  }
  100% {
    transform: translateY(0);
  }
`

const slideDown = keyframes`
  0% {
    top: -6vw;
  }
  100% {
    top: 0;
  }
`

const PageLayout = styled.div`
  position: relative;
  background-color: ${({ theme }) => theme.backgroundPrimary};
  color: ${({ theme }) => theme.textPrimary};
  width: 100%;
  height: 100%;
  min-height: 100vh;
  overflow: hidden;
`

const Container = styled.div`
  display: flex;
  flex-direction: ${({ column }) => (column ? 'column' : 'row')};
  min-height: ${({ minHeight }) => minHeight ?? '100%'};
  min-width: ${({ minWidth }) => minWidth ?? '100%'};
  justify-content: ${({ justifyContent }) => justifyContent ?? 'center'};
  align-items: ${({ alignItems }) => alignItems ?? 'center'};
  gap: ${({ gap }) => gap};

  @media (max-width: ${breakpoints.screenLG}) {
    flex-direction: ${({ columnReverse, mobileColumn }) =>
      columnReverse ? 'column-reverse' : mobileColumn ? 'column' : 'row'};
    max-width: ${({ mobileMaxWidth }) => mobileMaxWidth};
    min-height: ${({ mobileMinHeight }) => mobileMinHeight};
    justify-content: ${({ mobileJustifyContent }) => mobileJustifyContent};
    align-items: ${({ mobileAlignItems }) => mobileAlignItems};
    gap: ${({ mobileGap }) => mobileGap};
  }
`

const Cursor = styled.div`
  position: fixed;
  pointer-events: none;
  z-index: 10;
  transition: transform 0.1s linear;

  .pointer {
    width: 9rem;
    height: 9rem;
    box-shadow: inset 0 0 0 1px rgba(218, 87, 76, 0.5);
    border-radius: 100%;
    transition: transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  @media (max-width: ${breakpoints.screenMD}) {
    display: none;
  }
`

const Scroll = styled.div`
  position: fixed;
  left: 4vw;
  top: ${({ topPosition }) => topPosition};
  display: flex;
  flex-direction: column;
  z-index: 1;
  opacity: 0;
  transform: translateY(10vw);
  animation: ${fadeIn} 0.6s ease-out forwards,
    ${slideUp} 1s cubic-bezier(0.02, 0.98, 0.36, 1) forwards;
  animation-delay: 1.1s;

  @media (max-width: ${breakpoints.screenMD}) {
    display: none;
  }

  .text {
    font-size: 0.85vw;
    font-weight: 400;
    letter-spacing: 0.08em;
    color: ${({ theme, isReverse }) =>
      isReverse ? theme.textLight : theme.textPrimary};
    will-change: color;
    transition: color 0.6s ease;
    transform: rotate(-90deg);
    transform-origin: bottom;
    margin-left: -0.2em;

    @media (max-width: ${breakpoints.screenLG}) {
      font-size: 1.8vw;
      margin-left: -0.5em;
    }
  }

  .progress {
    position: relative;
    margin-top: 3em;
  }

  .dot {
    position: absolute;
    left: 0.25vw;
    width: 0.6vw;
    height: 0.6vw;
    border-radius: 100%;
    background-color: ${({ theme, isReverse }) =>
      isReverse ? theme.textLight : theme.textPrimary};
    will-change: background-color;
    transition: background-color 0.6s ease;
    opacity: 0;
    top: -6vw;
    animation: ${fadeIn} 0.6s ease-out forwards,
      ${slideDown} 1.2s cubic-bezier(0.02, 0.98, 0.36, 1) forwards;
    animation-delay: 1.1s;

    @media (max-width: ${breakpoints.screenLG}) {
      width: 1vw;
      height: 1vw;
      left: 0.1vw;
    }
  }

  .line {
    position: absolute;
    top: 0;
    left: 0.52vw;
    width: 0.08em;
    height: 4em;
    background-color: ${({ theme, isReverse }) =>
      isReverse ? theme.textLight : theme.textPrimary};
    will-change: height, background-color;
    transition: background-color 0.6s ease;

    @media (max-width: ${breakpoints.screenLG}) {
      width: 0.1em;
      height: 5em;
    }
  }
`

export { PageLayout, Container, Cursor, Scroll }
