import styled from 'styled-components'

import breakpoints from '../constants/breakpoints'

const FooterLayout = styled.div`
  width: 100%;
  height: 100%;

  .link-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${({ theme }) => theme.backgroundSecondary};
    padding: 5% 4vw 30%;

    @media (max-width: ${breakpoints.screenLG}) {
      padding: 5% 4vw;
    }
  }

  .link-wrapper-inner {
    padding: 12% 0;

    h1 {
      color: ${({ theme }) => theme.textLight};
      opacity: 0;
      transform: translateY(12vh);
      transition: opacity 0.6s ease-out,
        transform 1.6s cubic-bezier(0.02, 0.98, 0.36, 1);

      &.reveal {
        opacity: 1;
        transform: translateY(0);
      }
    }

    span {
      font-weight: 700;
    }
  }

  .connect-wrapper {
    color: ${({ theme }) => theme.textLight};
    background-color: ${({ theme }) => theme.backgroundDark};
    width: 78vw;
    padding: 10% 9vw 2%;
    margin-top: -25.5%;

    @media (max-width: ${breakpoints.screenLG}) {
      width: 100vw;
      padding: 8% 9vw 6%;
      margin-top: -1%;
    }
  }

  .colaborate {
    max-width: 30vw;

    @media (max-width: ${breakpoints.screenLG}) {
      max-width: 60vw;
      margin-bottom: 10%;
    }

    h3 {
      padding-bottom: 5%;

      @media (max-width: ${breakpoints.screenLG}) {
        padding-bottom: 4%;
      }
    }

    p {
      padding-bottom: 14%;
      opacity: 0;
      transform: translateY(8vh);
      transition: opacity 0.6s ease-out,
        transform 1.6s cubic-bezier(0.02, 0.98, 0.36, 1);

      @media (max-width: ${breakpoints.screenLG}) {
        padding-bottom: 8%;
      }

      &.reveal {
        opacity: 1;
        transform: translateY(0);
      }
    }

    .btn {
      opacity: 0;
      transform: translateY(10vh);
      transition: opacity 0.6s ease-out,
        transform 2s cubic-bezier(0.02, 0.98, 0.36, 1);

      &.reveal {
        opacity: 1;
        transform: translateY(0);
      }
    }
  }

  .social {
    padding-top: 1%;
    padding-right: 2vw;

    a {
      padding-right: 2vw;

      :hover {
        color: ${({ theme }) => theme.textHighlight};
      }
    }
  }

  .social-links-group {
    opacity: 0;
    transform: translateY(8vh);
    transition: opacity 0.6s ease-out,
      transform 1.6s cubic-bezier(0.02, 0.98, 0.36, 1);

    &.reveal {
      opacity: 1;
      transform: translateY(0);
    }
  }

  .social-newsletter-group {
    opacity: 0;
    transform: translateY(10vh);
    transition: opacity 0.6s ease-out,
      transform 2s cubic-bezier(0.02, 0.98, 0.36, 1);

    &.reveal {
      opacity: 1;
      transform: translateY(0);
    }
  }

  .social-title {
    font-size: 0.9vw;
    padding-bottom: 3%;
    opacity: 0.5;

    @media (max-width: ${breakpoints.screenLG}) {
      font-size: 1.8vw;
    }
  }

  .social-links {
    display: flex;
    margin-bottom: 16%;
  }

  .form-control {
    position: relative;
    padding-top: 5%;

    input {
      font-size: 0.9vw;
      border: 0;
      border-bottom: 0.06em solid ${({ theme }) => theme.textLight};
      background-color: transparent;
      width: 100%;
      color: ${({ theme }) => theme.textLight};
      padding: 6% 0;

      @media (max-width: ${breakpoints.screenLG}) {
        font-size: 1.8vw;
      }

      ::placeholder {
        font-size: 0.85vw;
        letter-spacing: 0.08em;

        @media (max-width: ${breakpoints.screenLG}) {
          font-size: 1.6vw;
        }
      }
    }

    button {
      position: absolute;
      bottom: 0.5em;
      right: -0.6vw;
      background-color: transparent;
      border: 0;
      padding: 3.5%;
      transition: opacity 0.3s ease;

      &:disabled {
        opacity: 0.2;
        cursor: text;
      }

      svg {
        @media (max-width: ${breakpoints.screenLG}) {
          width: 38px;
          height: auto;
        }
      }
    }
  }

  .success {
    padding-top: 10%;
  }

  .copyright-wrapper {
    margin-top: 14%;
    opacity: 0;
    transform: translateY(15vh);
    transition: opacity 0.6s ease-out,
      transform 2s cubic-bezier(0.02, 0.98, 0.36, 1);

    &.reveal {
      opacity: 0.5;
      transform: translateY(0);
    }
  }
`

export { FooterLayout }
