import styled, { keyframes } from 'styled-components'

import breakpoints from '../constants/breakpoints'

const menuOpenL = keyframes`
  0% {
    transform: translate(120%, 0%) matrix(1.2, 0, 0.2, 1, 0, 0);
  }
  100% {
    transform: translate(0%, 0%) matrix(1, 0, 0, 1, 0, 0);
  }
`

const menuCloseL = keyframes`
  0% {
    transform: translate(0%, 0%) matrix(1.2, 0, 0.2, 1, 0, 0);
  }
  100% {
    transform: translate(-100%, 0%) matrix(1, 0, 0, 1, 0, 0);
  }
`

const menuOpenLG = keyframes`
  0% {
    transform: translate(130%, 0%) matrix(1.3, 0, 0.2, 1, 0, 0);
  }
  100% {
    transform: translate(0%, 0%) matrix(1, 0, 0, 1, 0, 0);
  }
`

const menuCloseLG = keyframes`
  0% {
    transform: translate(0%, 0%) matrix(1.3, 0, 0.2, 1, 0, 0);
  }
  100% {
    transform: translate(-100%, 0%) matrix(1, 0, 0, 1, 0, 0);
  }
`

const menuOpenSM = keyframes`
  0% {
    transform: translate(150%, 0%) matrix(1.5, 0, 0.2, 1, 0, 0);
  }
  100% {
    transform: translate(0%, 0%) matrix(1, 0, 0, 1, 0, 0);
  }
`

const menuCloseSM = keyframes`
  0% {
    transform: translate(0%, 0%) matrix(1.5, 0, 0.2, 1, 0, 0);
  }
  100% {
    transform: translate(-100%, 0%) matrix(1, 0, 0, 1, 0, 0);
  }
`

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`

const fadeOut = keyframes`
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`

const slideLeft = keyframes`
  0% {
    transform: translateX(120px) skewX(10deg);
  }
  100% {
    transform: translateX(0) skewX(0);
  }
`

const slideRight = keyframes`
  0% {
    transform: translateX(0) skewX(0);
  }
  100% {
    transform: translateX(-400px) skewX(20deg);
  }
`

const MenuLayout = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  pointer-events: none;
  z-index: 2;

  &.open {
    pointer-events: auto;
  }

  &.close {
    pointer-events: none;
  }

  .bg-transition {
    position: fixed;
    left: 0;
    top: 0;
    display: block;
    background-color: ${({ theme }) => theme.backgroundSecondary};
    height: 100vh;
    width: 100vw;
    transform: translateX(-100%);
    animation-timing-function: cubic-bezier(0.7, 0, 0.3, 1);
    animation-fill-mode: forwards;

    &.open {
      animation-name: ${menuOpenL};
      animation-duration: 1.2s;

      @media (max-width: ${breakpoints.screenLG}) {
        animation-name: ${menuOpenLG};
        animation-duration: 1.1s;
      }

      @media (max-width: ${breakpoints.screenSM}) {
        animation-name: ${menuOpenSM};
        animation-duration: 1s;
      }
    }

    &.close {
      animation-name: ${menuCloseL};
      animation-duration: 1.2s;

      @media (max-width: ${breakpoints.screenLG}) {
        animation-name: ${menuCloseLG};
        animation-duration: 1.1s;
      }

      @media (max-width: ${breakpoints.screenSM}) {
        animation-name: ${menuCloseSM};
        animation-duration: 1s;
      }
    }
  }

  .menu-inner {
    position: relative;
    z-index: 1;
  }

  h1 {
    color: ${({ theme }) => theme.textLight};
  }

  a {
    opacity: 0;
    padding: 0 2vw;

    &.open {
      animation: ${fadeIn} 0.4s cubic-bezier(0.215, 0.61, 0.355, 1) forwards,
        ${slideLeft} 0.5s cubic-bezier(0.215, 0.61, 0.355, 1) forwards;

      :nth-child(1) {
        animation-delay: 0.925s;
      }

      :nth-child(2) {
        animation-delay: 1s;
      }

      :nth-child(3) {
        animation-delay: 1.075s;
      }

      :nth-child(4) {
        animation-delay: 1.15s;
      }
    }

    &.close {
      animation: ${fadeOut} 0.4s cubic-bezier(0.55, 0.055, 0.675, 0.19) forwards,
        ${slideRight} 0.5s cubic-bezier(0.55, 0.055, 0.675, 0.19) forwards;

      :nth-child(1) {
        animation-delay: 0.035s;
      }

      :nth-child(2) {
        animation-delay: 0.07s;
      }

      :nth-child(3) {
        animation-delay: 0.105s;
      }

      :nth-child(4) {
        animation-delay: 0.14s;
      }
    }

    &.active h1 {
      font-weight: 700;
    }
  }
`

export { MenuLayout }
