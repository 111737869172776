import { createGlobalStyle } from 'styled-components'

const GlobalStyles = createGlobalStyle`
  * {
    box-sizing: border-box;
    word-wrap: break-word;
  }

  html {
    min-height: 100%;
  }

  html,
  body {
    font-size: 16px;
    font-style: normal;
    font-stretch: normal;
    font-family: 'Source Sans Pro', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin: 0;
    padding: 0;
    -webkit-overflow-scrolling: touch;
    overscroll-behavior-y: none;
    overflow: overlay;
    overflow-x: hidden;
  }

  .no-scroll {
    overflow: hidden !important;
  }

  .hide {
    display: none !important;
  }

  ::-webkit-scrollbar {
    display: none;
  }

  ul,
  menu,
  dir {
    padding-left: 0;
  }

  a,
  button {
    color: inherit;
    text-decoration: none;
    transition: all 0.5s ease;
    cursor: pointer;
  }

  h1,
  h2,
  h3,
  h4,
  p,
  figure {
    margin: 0;
  }

  *:focus,
  *:active {
    outline: 0 !important;
  }

  ::-moz-selection { 
    color: #fff;
    background: #a0a094;
  }

  ::selection {
    color: #fff;
    background: #a0a094;
  }

  img {
    max-width: 100%;
  }

  .gatsby-image-wrapper img[src*=base64\\,] {
    image-rendering: -moz-crisp-edges;
    image-rendering: crisp-edges;
  }

  // Modal styles
  .ReactModal__Body--open {
    overflow-y: hidden !important;
  }

  .ReactModal__Overlay {
    opacity: 0;
    animation: fadeIn 0.1s linear forwards;
  }

  @keyframes fadeIn {
    to { opacity: 1; }
  }
`

export default GlobalStyles
