import React, { useEffect, useState, useRef } from 'react'

import { Scroll } from '../styles/PageLayout'

/**
 * A page scrolling indicator.
 * @returns {FunctionComponent}
 */
const ScrollIndicator = () => {
  const elRef = useRef()
  const [progress, setProgress] = useState(0)
  const [topPosition, setTopPosition] = useState(0)
  const [isReverse, setIsReverse] = useState(false)

  const onScroll = () => {
    // Set progress
    const windowScroll =
      document.body.scrollTop || document.documentElement.scrollTop
    const documentHeight =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight
    setProgress((windowScroll / documentHeight) * 55)

    // Set background color
    const footerWrapper = document.querySelector('.link-wrapper')
    const footerPosition = footerWrapper.offsetTop
    setIsReverse(windowScroll + elRef.current.offsetTop >= footerPosition)
  }

  useEffect(() => {
    // Set top position
    const coverImage = document.querySelector('.cover-image')
    setTopPosition(coverImage ? `${coverImage.offsetTop}px` : '70%')

    window.addEventListener('scroll', onScroll)

    return () => window.removeEventListener('scroll', onScroll)
  }, [])

  return (
    <Scroll ref={elRef} topPosition={topPosition} isReverse={isReverse}>
      <div className="text">Scroll</div>
      <div className="progress">
        <div className="line"></div>
        <div
          className="dot"
          style={{ transform: `translateY(${progress}px)` }}
        ></div>
      </div>
    </Scroll>
  )
}

export default ScrollIndicator
