const theme = {
  backgroundPrimary: '#f9f9f9',
  backgroundSecondary: '#262a33',
  backgroundDark: '#1d1f24',
  textPrimary: '#1d1f24',
  textSecondary: '#606060',
  textLight: '#fff',
  textHighlight: '#da574c',
  coverImgOverlay: '#3b3b4c',
  btnHoverBackground: '#a0a094',
  inputBorder: '#bfbfbf',
}

export default theme
